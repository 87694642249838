.gisthead {
  background-color: var(--page-header-bgColor, var(--color-page-header-bg));

  .pagehead-actions {
    // Remove styles that do not apply since this button sits inline in a group.
    .thread-subscription-status {
      padding: 0;
      margin: 0;
      border: 0;
    }

    // override the "unsubscribe" octicon on gist pages to be the default colour
    .octicon-mute {
      color: var(--fgColor-neutral, var(--color-neutral-emphasis));
    }
  }
}
